import React, { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'

import './Footer.scss'

import AppWrap from '../../wrapper/AppWrap'
import MotionWrap from '../../wrapper/MotionWrap'


const Footer = () => {
    const form = useRef()
    const [name, setName] = useState({
        value: undefined,
        isTouched: false
    })
    const [email, setEmail] = useState({
        value: undefined,
        isTouched: false
    })
    const [message, setMessage] = useState({
        value: undefined,
        isTouched: false
    })
    const [error, setError] = useState(null)
    const [isSuccess, setIsSuccess] = useState(null)
    const [isDisable, setIsDisable] = useState(false)

    const emailFormat = (testee) => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(testee);
    }

    const handleOnchangeEmail = (e) => {
        if (!emailFormat(e.target.value)) {
            setError(true)
        } else {
            setError(null)
        }

        setEmail({ ...email, value: e.target.value })
    }

    useEffect(() => {
        if (!name.value || !email.value || !message.value || error) {
            setIsDisable(true)
        } else {
            setIsDisable(false)
        }

    }, [name, email, message, error])



    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm('service_nij03zc', 'template_mfi3yyg', form.current, '_4C9l98XN7mSgsjzp')
            .then((result) => {
                if (result.text === 'OK') {
                    setName({ ...name, isTouched: false, value: "" })
                    setEmail({ ...email, isTouched: false, value: "" })
                    setMessage({ ...message, isTouched: false, value: "" })
                    setIsSuccess(true)
                }
            }, (error) => {
                console.log(error.text);
                setIsSuccess(false)
            });
    }

    return (
        <>
            <h2 className="head-text">
                Keep in
                <span> Touch </span>
                with Me
            </h2>

            <form ref={form} className="app__footer-form app__flex">
                <div className="app__flex">
                    <label htmlFor="user_name"></label>
                    <input
                        type="text"
                        name="user_name"
                        id="name"
                        className={`p-text ${name.isTouched && !name.value ? 'error-input' : null}`}
                        placeholder='Your Name'
                        value={name.value}
                        onChange={(e) => setName({ ...name, value: e.target.value })}
                        onBlur={() => setName({ ...name, isTouched: true })}
                        required
                    />
                </div>
                <div className="app__flex">
                    <label htmlFor="user_email"></label>
                    <input
                        type="email"
                        name="user_email"
                        id="email"
                        className={`p-text ${email.isTouched && (!email.value || error) ? 'error-input' : null}`}
                        placeholder='Your Email'
                        value={email.value}
                        onChange={handleOnchangeEmail}
                        onBlur={() => setEmail({ ...email, isTouched: true })}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="message"></label>
                    <textarea
                        type="text"
                        name="message"
                        id="message"
                        className={`p-text ${message.isTouched && !message.value ? 'error-input' : null}`}
                        placeholder='Your Message'
                        value={message.value}
                        onChange={(e) => setMessage({ ...message, value: e.target.value })}
                        onBlur={() => setMessage({ ...message, isTouched: true })}
                        required
                    />
                </div>
                <div className="app__footer_button-message app__flex">
                    <button type='button' className={`p-text ${isDisable === false ? 'enabled-button' : ''}`} onClick={sendEmail} disabled={isDisable}>SEND</button>

                    <div className="app__result-message">
                        {isSuccess !== null && (
                            isSuccess ? (
                                <span className="success-message">Thank you for reaching out!</span>
                            ) : (
                                <span className="failure-message">Error! Please check your input!</span>
                            )
                        )
                        }
                    </div>
                </div>
            </form>

        </>
    )
}

export default AppWrap(
    MotionWrap(Footer, 'app__footer'),
    'contact',
    'app__white-bg'
)