import React from 'react'
import {motion} from 'framer-motion'

import ecommerceImg from '../../assets/about-shopify-3.png'
import webappImg from '../../assets/about-webapp.png'
import uiuxImg from '../../assets/about-uiux.png'

import './About.scss'
import MotionWrap from '../../wrapper/MotionWrap'
import AppWrap from '../../wrapper/AppWrap'

const abouts = [
    {
      imageUrl: ecommerceImg,
      title: 'E-Commerce Store',
      description: 'Build your business with the Shopify platform to sell online, offline, and everywhere in between.'
    },
    {
      imageUrl: webappImg,
      title: 'Website Application',
      description: 'Use the ReactJS technology to create a beautiful and useful Website Application, what will serve your demand or your business.'
    },
    {
      imageUrl: uiuxImg,
      title: 'UI/UX Mockup Design',
      description: 'Make your website/application ideas into reality in my pretty UI/UX mock-up designs.'
    },
]

function About() {
  return (
    <>
      <h2 className="head-text">
        I Know That
        <span> Good Development</span>
        <br />
        Means
        <span> Good Business</span>
      </h2>

      <div className="app__profiles">
      {abouts.map((about,index) => (
        <motion.div
          whileInView={{opacity: 1}}
          whileHover={{scale: 1.1}}
          transition={{duration: 0.5, type: 'tween'}}
          className="app__profile-item"
          key={about.title + index}
        >
          <img src={about.imageUrl} alt={about.title} />
          <h2 className="bold-text" style={{marginTop: 20}} >{about.title}</h2>
          <p className="p-text" style={{marginTop: 15}} >{about.description}</p>

        </motion.div>
      ))}
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__white-bg'
)