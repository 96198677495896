import javascript_icon from '../../assets/javascript.png'
import react_icon from '../../assets/react.png'
import node_icon from '../../assets/node.png'
import shopify_icon from '../../assets/shopify.png'
import redux_icon from '../../assets/redux.png'
import graphql_icon from '../../assets/graphql.png'
import mongodb_icon from '../../assets/mongodb.png'
import sass_icon from '../../assets/sass.png'
import git_icon from '../../assets/git.png'
import figma_icon from '../../assets/figma.png'
import typescript_icon from '../../assets/typescript.png'
import prisma_icon from '../../assets/prisma.png'

export const skillsIndexList = [
    {
        icon: javascript_icon,
        name: 'JavaScript',
        bgColor: '#EDF2F8'
    },
    {
        icon: typescript_icon,
        name: 'TypeScript',
        bgColor: '#EDF2F8'
    },
    {
        icon: react_icon,
        name: 'React',
        bgColor: '#EDF2F8'
    },
    {
        icon: node_icon,
        name: 'NodeJS',
        bgColor: '#EDF2F8'
    },
    {
        icon: shopify_icon,
        name: 'Shopify',
        bgColor: '#EDF2F8'
    },
    {
        icon: redux_icon,
        name: 'Redux',
        bgColor: '#EDF2F8'
    },
    {
        icon: graphql_icon,
        name: 'GraphQL',
        bgColor: '#EDF2F8'
    },
    {
        icon: prisma_icon,
        name: 'Prisma',
        bgColor: '#EDF2F8'
    },
    {
        icon: mongodb_icon,
        name: 'MongoDB',
        bgColor: '#EDF2F8'
    },
    {
        icon: sass_icon,
        name: 'Sass',
        bgColor: '#EDF2F8'
    },
    {
        icon: git_icon,
        name: 'Git',
        bgColor: '#EDF2F8'
    },
    {
        icon: figma_icon,
        name: 'Figma',
        bgColor: '#EDF2F8'
    },
];

export const experienceIndexList = [
    {
        job_title: 'Software Developer',
        employer: 'ServeScape',
        location: 'ServeScape, Atlanta, GA',
        time_begin: 2023,
        time_end: 'Present'
    },
    {
        job_title: 'Shopify/ReactJS Developer',
        employer: 'Freelancer',
        location: null,
        time_begin: 2021,
        time_end: 'Present'
    },
    {
        job_title: 'Front-End Developer',
        employer: 'Holy Vietnamese Martyrs Catholic Church',
        location: 'Atlanta, GA',
        time_begin: 2020,
        time_end: 'Present'
    },
]