import React from 'react'

import logo from '../../assets/JN-logo.png'

import './Navbar.scss'
import menuItems from '../menu-items'

const Navbar = () => {
  return (
    <nav className="navbar">
        <div className="navbar__logo">
            <a href="#home">
                <img src={logo} alt="logo" />
            </a>
        </div>
        <ul className="navbar__links">
            {menuItems.map((item, index) => (
                <li key={index} className="app__flex p-text">
                    <div/>
                    <a href={`#${item.name}`}>{item.name}</a>
                </li>
            ))}
        </ul>
    </nav>
  )
}

export default Navbar