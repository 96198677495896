import React from 'react'
import menuItems from '../menu-items'
import './NavbarMobile.scss'

const NavbarMobile = () => {
  return (
    <nav className="navbar-mobile">
        <ul className="navbar-mobile__links">
            {menuItems.map((item, index) => (
                <li key={index} className="app__flex p-text">
                    <div>
                        {item.icon}
                    </div>
                    <a href={`#${item.name}`}>{item.name}</a>
                </li>
            ))}
        </ul>
    </nav>
  )
}

export default NavbarMobile