import angela_semeone from '../../assets/angela-semeone-screen.jpeg'
import comfort_wheel from '../../assets/comfort-wheel-screen.png'
import olive_shop from '../../assets/olive-shop-screen.jpeg'
import hvmatl from '../../assets/hvmatl-org-screen.jpeg'
import scapify from '../../assets/scapify.png'
import erickson_black from '../../assets/erickson-black.jpeg'
import moltipurpose_vending from '../../assets/moltipurpose-vending.jpg'
import servescape from '../../assets/servescape.png'
import glassfarmnursery from '../../assets/glassfarmnursery.png'

const worksIndex = [
    {
        id: 1,
        imgUrl: servescape,
        tags: 'E-Commerce',
        name: 'ServeScape',
        description: `South's landscape marketplace based at Atlanta, Georgia`,
        technology: 'Shopify',
        password: null,
        isShopify: true,
        isMockup: false,
        projectLink: 'https://servescape.com/',
    },
    {
        id: 2,
        imgUrl: scapify,
        tags: 'E-Commerce',
        name: 'Scapify',
        description: 'Master Gardener AI Chatbot Service in Atlanta',
        technology: 'Shopify',
        password: null,
        isShopify: true,
        isMockup: false,
        projectLink: 'https://scapify.com/',
    },
    {
        id: 3,
        imgUrl: glassfarmnursery,
        tags: 'E-Commerce',
        name: 'Glass Farm Nursery',
        description: 'Est since 1970, Glass Farm Nursery is a plants nursery and retail shop at Chattanooga area.',
        technology: 'Shopify',
        password: null,
        isShopify: true,
        isMockup: false,
        projectLink: 'https://glassfarmnursery.net/',
    },
    {
        id: 4,
        imgUrl: hvmatl,
        tags: 'Web App',
        name: 'HVMATL',
        description: 'Holy Vietnamese Martyrs Catholic Church in Atlanta',
        technology: 'React, FTP Server, Heroku',
        isShopify: false,
        isMockup: false,
        projectLink: 'https://www.hvmatl.org/',
    },
    {
        id: 5,
        imgUrl: moltipurpose_vending,
        tags: 'Web App',
        name: 'Moltipurpose Vending LLC',
        description: 'A Florida Vending Machines Provider',
        technology: 'WordPress',
        isShopify: false,
        isMockup: false,
        projectLink: 'https://moltipurposevending.com/',
    },
    {
        id: 6,
        imgUrl: comfort_wheel,
        tags: 'E-Commerce',
        name: 'Comfort Wheel',
        description: 'A Florida Vehicle Rental Service Company',
        technology: 'Shopify',
        password: 'umpida',
        isShopify: true,
        isMockup: false,
        projectLink: 'https://comfort-wheel.myshopify.com/',
    },
    {
        id: 7,
        imgUrl: angela_semeone,
        tags: 'UI/UX',
        name: 'Angela Semeone',
        description: 'A Nashville Artist Collection and Canvas Painting Store',
        technology: 'Figma',
        isShopify: false,
        isMockup: true,
        projectLink: angela_semeone,
    },
    {
        id: 8,
        imgUrl: olive_shop,
        tags: 'E-Commerce',
        name: 'Olive Shop',
        description: 'A Fashion Shop',
        technology: 'Shopify',
        password: 'olive-shop',
        isShopify: true,
        isMockup: false,
        projectLink: 'https://jomar-cool-shop.myshopify.com/',
    },
    {
        id: 9,
        imgUrl: erickson_black,
        tags: 'E-Commerce',
        name: 'Erickson Black',
        description: 'A Mexican Print On Demand T-Shirt Store',
        technology: 'Shopify',
        // password: 'olive-shop',
        isShopify: true,
        isMockup: true,
        projectLink: erickson_black,
    },


]

export default worksIndex;