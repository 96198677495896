import { AiFillHome } from 'react-icons/ai'
import { BsFillPersonFill } from 'react-icons/bs'
import { MdWork, MdFeedback, MdEmail } from 'react-icons/md'
import { GiSkills } from 'react-icons/gi'

const menuItems = [
    { name: 'home', icon: <AiFillHome /> },
    { name: 'about', icon: <BsFillPersonFill /> },
    { name: 'works', icon: <MdWork /> },
    { name: 'skills', icon: <GiSkills /> },
    { name: 'testimonials', icon: <MdFeedback /> },
    { name: 'contact', icon: <MdEmail /> }
]

export default menuItems;