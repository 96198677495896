import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import './Skills.scss'
import { experienceIndexList, skillsIndexList } from './SkillsIndex'
import AppWrap from '../../wrapper/AppWrap'
import MotionWrap from '../../wrapper/MotionWrap'

const Skills = () => {
    const [skills, setSkills] = useState([])
    const [experiences, setExperiences] = useState([])


    useEffect(() => {
        setSkills(skillsIndexList)
        setExperiences(experienceIndexList)
    }, [])

    return (
        <>
            <h2 className="head-text">Skills & <span>Experience</span></h2>

            <div className="app__skills-container">
                <motion.div className='app__skills-list'>
                    {skills.map((skill) => (
                        <motion.div
                            whileInView={{ opacity: [0, 1] }}
                            transition={{ duration: 0.5 }}
                            className="app__skills-item app__flex"
                            key={skill.name}
                        >
                            <div className="app__flex" style={{ backgroundColor: skill.bgColor }}>
                                <img src={skill.icon} alt={skill.name} />
                            </div>
                            <p className="p-text">{skill.name}</p>
                        </motion.div>
                    ))}
                </motion.div>

                <motion.div className='app__skills-exp'>
                    {experiences?.map((experience) => (
                        <motion.div
                            className='app__skills-exp-item'
                            key={experience.time_begin}
                        >
                            <div className="app__skills-exp-year">
                                <p className="bold-text">{experience.time_begin}</p>
                                <p className="bold-text">|</p>
                                <p className="bold-text">{experience.time_end}</p>
                            </div>
                            <motion.div className='app__skills-exp-works'>
                                <motion.div
                                    whileInView={{ opacity: [0, 1] }}
                                    transition={{ duration: 0.5 }}
                                    className="app__skills-exp-work"
                                    id={experience.name}
                                    key={experience.name}
                                >
                                    <h4 className="bold-text">{experience.job_title}</h4>
                                    <p className="p-text">{experience.employer}{experience.location ? `, ${experience.location}` : ''}</p>
                                </motion.div>
                            </motion.div>
                        </motion.div>

                    ))}
                </motion.div>
            </div>
        </>
    )
}

export default AppWrap(
    MotionWrap(Skills, 'app__skills'),
    'skills',
    'app__white-bg'
)