const testimonialsList = [
    {
        name: "Mario C.",
        company: "Scapify - ServeScape",
        feedback: "Beautiful and incredible job, Jomar. Thank you so much!",
        rating: 5
    },
    {
        name: "Matt H.",
        company: "Danielle Sandusky",
        feedback: "Job done. Clean and fast. Just the way we like it!",
        rating: 5
    },
    {
        name: "Angela S.",
        company: "Angela Simeone - Artist Nashville",
        feedback: "He is the guy if you want premium quality work. Very much impressed by the quality of work he did on my Shopify store.",
        rating: 5
    },
    {
        name: "Theresa N.",
        company: "Presente Amor",
        feedback: "He did a very good job. I love my website and will definitely come back for more services. I highly recommend him.",
        rating: 4.5
    },
    {
        name: "Cecilia N.",
        company: "Somagm",
        feedback: "Super fast and easy to work with.",
        rating: 5
    },
]

export default testimonialsList;