import React from 'react'
import { motion } from 'framer-motion'
import Carousel from 'react-multi-carousel'
import { ImQuotesLeft } from 'react-icons/im'

import './Testimonials.scss'
import AppWrap from '../../wrapper/AppWrap'
import MotionWrap from '../../wrapper/MotionWrap'

import testimonialsList from './TestimonialsIndex'


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
}

// const ArrowSlideButtons = ({ next, previous, goToSlide, ...rest }) => {
//     const { carouselState: { currentSlide } } = rest;
//     return (
//         <div className="app__testimonials-slide-buttons app__flex">
//             <button className={`${currentSlide === 0 ? 'disable' : ''} app__testimonials-button left__slide-button`} onClick={() => previous()}>&#10094;</button>
//             <button className="app__testimonials-button right__slide-button" onClick={() => next()}>&#10095;</button>
//         </div>
//     )
// }

const Testimonials = () => {
    const testimonials = testimonialsList

    return (
        <>
            <h2 className="head-text">
                <span>Testimonials</span>
            </h2>

            <motion.div
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className='app__testimonial-container'
            >
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    customTransition="all 0.4s ease-in-out"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    renderButtonGroupOutside={true}
                    // customButtonGroup={<ArrowSlideButtons />}
                    arrows={false}
                    itemClass="carousel-item-padding-40-px"
                    className='carousel-app-custom'
                >
                    {testimonials.map((test, index) => (
                        <div className="app__testimonial-item app__flex" key={index}>
                            <div className="app__testimonial-content">
                                <ImQuotesLeft />
                                <p className="p-text app__testimonial-feedback">{test.feedback}</p>
                                <div>
                                    <h4 className="bold-text app__testimonial-name">{test.name}</h4>
                                    <h5 className="p-text app__testimonial-company">{test.company}</h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>

            </motion.div>
        </>
    )
}

export default AppWrap(
    MotionWrap(Testimonials, 'app__testimonials'),
    'testimonials',
    'app__primarybg'
)