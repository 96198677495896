import React from 'react'
import SocialMedia from '../components/SocialMedia/SocialMedia'

const AppWrap = (Component, idName, className) => function HOC() {
  return (
    <div id={idName} className={`app__container ${className}`}>
        <div className="app__wrapper app__flex">
            <Component/>

            <div className="copyright">
                <p className="p-text">@2023 JOMAR NGUYEN</p>
                <p className="p-text">All right reserved</p>
            </div>
        </div>

        <SocialMedia/>
    </div>
  )
}

export default AppWrap