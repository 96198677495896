import './App.scss';
import NavbarMobile from './components/Navbar-mobile/NavbarMobile';
import Navbar from './components/Navbar/Navbar';
import About from './container/About/About';
import Footer from './container/Footer/Footer';
import Header from './container/Header/Header';
import Skills from './container/Skills/Skills';
import Testimonials from './container/Testimonials/Testimonials';
import Works from './container/Works/Works';

function App() {
  return (
    <div className="app">
      <Navbar />
      <NavbarMobile/>
      <Header/>
      <About/>
      <Works/>
      <Skills/>
      <Testimonials/>
      <Footer/>
    </div>
  );
}

export default App;
