import React from 'react'
import {motion} from 'framer-motion'

import profileImg from '../../assets/jmn-gradient.png'
import circle from '../../assets/circle.svg'
import reactIcon from '../../assets/react.png'
import figmaIcon from '../../assets/figma.png'
import shopifyIcon from '../../assets/shopify.png'

import './Header.scss'
import AppWrap from '../../wrapper/AppWrap'

const scaleVariants = {
    whileInView: {
        scale: [0,1],
        opacity: [0,1],
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    }
}

const Header = () => {
  return (
    <div className='app__header app__flex' id="home" >
        <motion.div
            whileInView={{x:[-100,0], opacity: [0,1]}}
            transition={{duration: 0.5}}
            className="app__header-info"
        >
            <div className="app__header-badge">
                <div className="badge-cmp app__flex">
                    <span>👋</span>
                    <div>
                        <p className="p-text">Hello, I am</p>
                        <h1 className="app__header-text">Jomar</h1>
                    </div>
                </div>
                <div className="tag-cmp app__flex">
                    <p className="p-text">Web Developer</p>
                    <p className="p-text">Freelancer</p>
                </div>
            </div>
        </motion.div>

        <motion.div
            whileInView={{opacity:[0,1]}}
            transition={{duration: 0.5, delayChildren: 0.5}}
            className="app__header-img"
        >
            <img src={profileImg} alt="profile_img" className='app__header-portfolio-img'/>
            <motion.img
                whileInView={{scale: [0,1]}}
                transition={{duration: 1, ease: 'easeInOut'}}
                src={circle}
                alt="profile_circle"
                className='overlay_circle'
            />
        </motion.div>

        <motion.div
            variants={scaleVariants}
            whileInView={scaleVariants.whileInView}
            className='app__header-circles'
        >
            {[shopifyIcon, reactIcon, figmaIcon].map((icon, index) => (
                <div className="circle-cmp app__flex" key={`circle-${index}`}>
                    <img src={icon} alt="profile_bg" />
                </div>
            ))}
        </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'header')