import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { AiFillEye } from 'react-icons/ai'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import './Works.scss'
import AppWrap from '../../wrapper/AppWrap'
import MotionWrap from '../../wrapper/MotionWrap'

import worksIndex from './worksIndex'

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
}

const ArrowSlideButtons = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div className="app__work-slide-buttons app__flex">
            <button className={`${currentSlide === 0 ? 'disable' : ''} app__work-button left__slide-button`} onClick={() => previous()}>&#10094;</button>
            <button className="app__work-button right__slide-button" onClick={() => next()}>&#10095;</button>
        </div>
    )
}

const Works = () => {
    const [activeFilter, setActiveFilter] = useState('All');
    const works = worksIndex
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 })
    const [filterWork, setFilterWork] = useState([])


    useEffect(() => {
        setFilterWork(worksIndex)
    }, [])



    const handleFilterClick = (item) => {
        setActiveFilter(item)
        setAnimateCard({ y: 100, opacity: 0 })

        setTimeout(() => {
            setAnimateCard({ y: 0, opacity: 1 })

            if (item === 'All') {
                setFilterWork(works)
            } else {
                setFilterWork(works.filter((work) => work.tags.includes(item)))
            }
        }, 500);
    }

    return (
        <>
            <h2 className="head-text">
                My Creative
                <span> Portfolio </span>
                Section
            </h2>

            <div className="app__work-filter">
                {['All', 'E-Commerce', 'Web App', 'UI/UX'].map((item, index) => (
                    <div
                        key={index}
                        className={`app__work-filter-item app__flex ${activeFilter === item ? 'item-active' : ''}`}
                        onClick={() => handleFilterClick(item)}
                    >
                        {item}
                    </div>
                ))}
            </div>

            <motion.div
                animate={animateCard}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className='app__work-portfolio'
            >
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    infinite={false}
                    customTransition="all 0.4s ease-in-out"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ArrowSlideButtons />}
                    arrows={false}
                    itemClass="carousel-item-padding-40-px"
                    className='carousel-app-custom'
                >
                    {filterWork.map((work, index) => (
                        <div className="app__work-item app__flex" key={index} >
                            <div className="app__work-img app__flex">
                                <img src={work.imgUrl} alt={work.name} />

                                <motion.div
                                    whileHover={{ opacity: [0, 1] }}
                                    transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                                    className='app__work-hover app__flex'
                                >
                                    <a href={work.projectLink} target='_blank' rel="noreferrer noopener">
                                        <motion.div
                                            whileInView={{ scale: [0, 1] }}
                                            whileHover={{ scale: [1, 0.9] }}
                                            transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                                            className='app__flex'
                                        >
                                            <AiFillEye />
                                        </motion.div>
                                    </a>
                                </motion.div>
                            </div>

                            <div className="app__work-content app__flex">
                                <h4 className="bold-text">{work.name}</h4>
                                <p className="p-text" style={{ marginTop: 10 }}><b>Technology:</b> {work.technology}</p>
                                <p className="p-text" style={{ marginTop: 10 }}><b>Description:</b> {work.description}</p>
                                {work.password &&
                                    <p className="p-text" style={{ marginTop: 10 }}><b>Password:</b> {work.password}</p>
                                }

                                <div className="app__work-tag app__flex">
                                    <p className="p-text">{work.tags}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>

            </motion.div>
        </>
    )
}

export default AppWrap(
    MotionWrap(Works, 'app__works'),
    'works',
    'app__primary-bg'
)