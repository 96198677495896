import React from 'react'
import {FaGithubSquare, FaLinkedin} from 'react-icons/fa'

import './SocialMedia.scss'


const SocialMedia = () => {
  return (
   <div className="app__social">
        <FaGithubSquare onClick={() => window.open('https://github.com/JomarHAN')}/>
        <FaLinkedin onClick={() => window.open('https://www.linkedin.com/in/jomar-nguyen/')}/>
   </div>
  )
}

export default SocialMedia